import React from 'react'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageIntro from '../components/pageIntro/pageIntro'
import Gallery from '../components/imageGallery/gallery'

function Restaurant({ data }) {
    const gallery = data.gallery.edges.map(({ node }) => ({ ...node }))
    const { t } = useTranslation('restaurant')
    return (
        <Layout darkHeader>
            <SEO
                title={t('title')}
                description={t('seoDescription')}
                image="../assets/images/armenakiview.jpg"
                alreadyTranslated
            />
            <PageIntro title={t('headingTitle')} subtitle={t('headingSubtitle')} />
            <Fade right duration={1000} delay={400} distance="10px">
                <StaticImage
                    src="../assets/images/armenakiview.jpg"
                    layout="fullWidth"
                    style={{ maxHeight: '370px' }}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Αρμενάκι κατοικίες κεντρική"
                />
            </Fade>
            <Container className="mt-5 mb-5">
                <Row>
                    <Col md={8}>
                        <Fade bottom duration={1000} delay={400} distance="10px">
                            <p className="mt-2">{t('paragraph1')}</p>
                        </Fade>
                        <Fade bottom duration={1000} delay={400} distance="10px">
                            <p className="mt-2">{t('paragraph2')}</p>
                        </Fade>
                        <Fade bottom duration={1000} delay={400} distance="10px">
                            <p className="mt-2">{t('paragraph3')}</p>
                        </Fade>
                        <Fade bottom duration={1000} delay={400} distance="10px">
                            <p className="mt-2">{t('paragraph4')}</p>
                        </Fade>
                    </Col>
                    <Col md={4}>
                        <Fade right duration={1000} delay={800} distance="10px">
                            <StaticImage
                                src="../assets/images/tonos.jpg"
                                layout="fullWidth"
                                style={{ maxHeight: '350px' }}
                                formats={['AUTO', 'WEBP', 'AVIF']}
                                alt="Αρμενάκι κατοικίες κεντρική"
                            />
                        </Fade>
                    </Col>
                </Row>
            </Container>
            <Gallery gallery={gallery} />
        </Layout>
    )
}

export const restaurantQuery = graphql`
    query {
        gallery: allFile(filter: { relativeDirectory: { eq: "assets/images/food" } }) {
            edges {
                node {
                    id
                    publicURL
                    base
                    childrenImageSharp {
                        gatsbyImageData(webpOptions: { quality: 50 })
                    }
                }
            }
        }
    }
`

Restaurant.propTypes = {
    data: PropTypes.shape({
        gallery: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        id: PropTypes.string,
                        publicURL: PropTypes.string,
                        base: PropTypes.string,
                    }),
                }),
            ),
        }),
    }),
}

Restaurant.defaultProps = {
    data: {},
}

export default Restaurant
